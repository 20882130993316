import React from "react"
import { Typography, Container, List, ListItem } from "@mui/material"

const TermsAndConditions = () => {
  return (
    <section>
      <Container fixed sx={{ marginTop: '50px', marginBottom: '50px' }}>
        <Typography mb={6} variant="h4">
          Terms & Conditions
        </Typography>
        <Typography>
          To maintain your PADs membership, your Assistance Dogs health and behaviour must be upheld to a high standard.
          As such, annual behaviour and veterinary checks are required. Once approved you will be notified automatically at 10 months
          from your membership activation for renewal (we allow a two-month period to complete the annual vet and behaviour checks).
          Once these checks are completed, you will receive a new ID card with updated membership dates.
        </Typography>
        <Typography sx={{ mt: 3 }}>
          In order for your application to be approved, you must agree to our Qualified Member Terms;
        </Typography>
        <List>
          <ListItem>
            1. As a Qualified PADs Member I agree to ensure that my PADs Assistanc
            Dog is kept in good health. Should my PAD become unwell I shall follow
            all veterinary advice and shall allow my PAD to recover fully before
            returning to assistance work.
          </ListItem>
          <ListItem>
            2. As a Qualified PADs Member I agree to ensure that my PAD is kept clea
            and well groomed for access into public spaces and should not emit a
            foul smell. Should my PAD become excessively muddy from a walk I
            shall endeavour to clean my PAD before taking into public buildings.
          </ListItem>
          <ListItem>
            3. As a Qualified PADs Member I agree to ensure that I use only force fre
            training methods, will advocate for my PADs well-being, will not force my
            PAD to perform behaviours, will not use any aversive equipment (e.g slip
            leads - please read Training Guide for full details).
          </ListItem>
          <ListItem>
            4. As a Qualified PADs Member I agree to address any behavioural issue
            that arise with a qualified professional (Between 33% and 80% o
            behavioural cases are related to pain, working with a qualifie
            Behaviourist and your Veterinarian will help address any issues).
          </ListItem>
          <ListItem>
            5. As a Qualified PADs Member you will receive a PADs harness It is no
            required by law for your PAD to wear the harness but it may assist with
            gaining access. As a Qualified PADs Member I agree to uphold th
            reputation of PADs Foundation by handling any access issues in a
            reasonable manner. Should you encounter issues with access where
            reasonable adjustments have not been made please contact PADs.
          </ListItem>
          <ListItem>
            6. As a Qualified PADs Member I agree to ensure that my PADs dog ID car
            is stowed in the PADs harness when in use. (This is to prevent fraudulent
            harnesses from non members being used as a PADs harness).
          </ListItem>
          <ListItem>
            7. As a Qualified PADs Member I agree to pay the Qualified PADs Mem
            application annual renewal fee to contribute to the manufacture of PADs
            hardware.
          </ListItem>
          <ListItem>
            8. As a Qualified PADs Member I agree to ensure that I hold up to dat
            Public Liability insurance. (We recommend that members also have
            health insurance for their dog but it is not a requirement)
          </ListItem>
        </List>
      </Container>
    </section>
  )
}

export default TermsAndConditions
