import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  deleteDoc,
} from 'firebase/firestore'

const createUser = async (data, uid, db) => {
  try {
    await setDoc(doc(db, 'users', uid), {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      role: data.role,
      acceptedTerms: data.terms,
    })

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

const updateUser = async (data, uid, db) => {
  try {
    const docRef = doc(db, 'users', uid)
    await updateDoc(docRef, data)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

const getUser = async (uid, db) => {
  try {
    const docRef = doc(db, 'users', uid)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      return docSnap.data()
    } else {
      console.log('no user found')
    }

    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

const listUsers = async (db) => {
  try {
    const querySnap = await getDocs(collection(db, 'users'))
    const users = querySnap.docs.map((doc) => {
      return { ...doc.data(), id: doc.id }
    })

    return users
  } catch (error) {
    console.error(error)
    return []
  }
}

const deleteUser = async (uid, db) => {
  try {
    await deleteDoc(doc(db, 'users', uid))
    await deleteDoc(doc(db, 'applications', uid))

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export { createUser, updateUser, getUser, listUsers, deleteUser }
