import React, { useState, useEffect } from 'react'
import {
  Alert,
  Container,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinearProgress from '@mui/material/LinearProgress'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import Snackbar from '@mui/material/Snackbar'
import { Link } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthContext'
import { listTrainers, linkTrainer, unlinkTrainer } from '../../data/trainers'
import dayjs from 'dayjs'
import LinkTrainerDetails from './LinkTrainerDetails';

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const TrainerTable = () => {
  const { currentUser, db, functions } = useAuth()
  const [loadingList, setLoadingList] = useState(true)
  const [linkedAccountModal, showLinkedAccountModal] = useState(false)
  const [trainerList, setTrainerList] = useState([])
  const [trainerId, setTrainerId] = useState('')

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    getTrainerList()
  }, [])

  const unlinkAccount = async (trainerId) => {
    const update = await unlinkTrainer(db, trainerId)

    if (update) {
      getTrainerList()
      setAlert({
        open: true,
        message: 'Trainer successfully unlinked!',
        type: 'success',
      })
    }
  }

  const columns = [
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => `${params.row.firstName} ${params.row.lastName}`,
    },
    {
      field: 'linkedAccount',
      headerName: 'Linked Account ID',
      width: 150,
      flex: 1,
      renderCell: (params) => `${params.row.linkedAccount ? params.row.linkedAccount : 'No account linked'}`,
    },
    {
      field: 'linkedAccountName',
      headerName: 'Linked Account Name',
      width: 150,
      flex: 1,
      renderCell: (params) => `${params.row.linkedAccountName ? params.row.linkedAccountName : 'No account linked'}`,
    },
    {
      field: 'action',
      width: 100,
      type: 'actions',
      headerName: 'Action',
      sortable: false,
      getActions: (params) => [
        <GridActionsCellItem
          onClick={() => {
            setTrainerId(params.row.id)
            showLinkedAccountModal(!linkedAccountModal)
          }}
          icon={<InsertLinkIcon />}
          label="Link Account"
          key={2}
          showInMenu
        />,
        <GridActionsCellItem
          onClick={() => unlinkAccount(params.row.id)}
          icon={<LinkOffIcon />}
          label="Unlink Account"
          key={2}
          showInMenu
        />,
      ],
    },
  ]

  const getTrainerList = async () => {
    setLoadingList(true)
    const list = await listTrainers(db)
    if (list) setTrainerList(list)
    setLoadingList(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  const callback = () => {
    getTrainerList()
    showLinkedAccountModal(false)
  }

  if (currentUser && ['admin', 'super-admin'].includes(currentUser.role)) {
    return (
      <section>
        <Container fixed sx={{ marginTop: '50px', maxWidth: '100%' }}>
          <Snackbar
            autoHideDuration={6000}
            onClose={handleClose}
            open={alert.open}
          >
            <Alert
              onClose={handleClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Typography mb={6} variant="h4">
            Manage Trainers
          </Typography>
          <Link to="/admin/dashboard">{'< Back to Admin Dashboard'}</Link>

          <Dialog fullWidth maxWidth="sm" open={linkedAccountModal} onClose={() => showLinkedAccountModal(false)}>
            <DialogTitle>Link Trainer Account</DialogTitle>
            <DialogContent>
              <LinkTrainerDetails callback={callback} trainerId={trainerId} />
            </DialogContent>
          </Dialog>
          <Container component="main" sx={{ marginTop: 5, marginBottom: 5 }}>
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid
                checkboxSelection={false}
                loading={loadingList}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                rows={trainerList}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
              />
            </div>
          </Container>
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Alert severity="error">You are not authorized to view this page</Alert>
      </Container>
    </section>
  )
}

export default TrainerTable
