import React, { useState, useEffect } from 'react'
import { Alert, Container, Typography, Dialog, DialogTitle, DialogContent, Button, Grid, OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { LoadingButton } from '@mui/lab'
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import Snackbar from '@mui/material/Snackbar'
import { Link } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuth } from '../../contexts/AuthContext'
import { listUsers, deleteUser, getUser } from '../../data/user'
import AdditionalData from './AdditionalData'
import CloseIcon from '@mui/icons-material/Close';

const UserTable = () => {
  const { currentUser, db } = useAuth()
  const [loadingList, setLoadingList] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [userList, setUserList] = useState([])
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [unfilteredUsers, setUnfilteredUsers] = useState([])
  const [search, setSearch] = useState('')

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    getUserList()
  }, [])

  const columns = [
    { field: 'firstName', headerName: 'First name', flex: 0.5 },
    { field: 'lastName', headerName: 'Last name', flex: 0.5 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'role', headerName: 'Status', flex: 0.5, renderCell: (params) => (String(params.row.role).charAt(0).toUpperCase() + String(params.row.role).slice(1)) },
    { field: 'registeredId', headerName: 'Registered ID', flex: 0.5 },
    {
      field: 'action',
      width: 100,
      type: 'actions',
      headerName: 'Action',
      sortable: false,
      getActions: (params) => [
        <GridActionsCellItem
          onClick={() => removeUser(params.row.id)}
          icon={<DeleteIcon />}
          label="Delete User"
          key={2}
          showInMenu
        />,
        <GridActionsCellItem
          onClick={() => {
            setSelectedUser(params.row)
            setShowAdditionalInfo(!showAdditionalInfo)
          }}
          icon={<VisibilityIcon />}
          label="View Additional Info"
          key={1}
          showInMenu
        />,
      ],
    },
  ]

  const removeUser = async (userId) => {
    setDeleting(true)
    if (
      window.confirm(
        'Are you sure you want to delete this user? Deleting this ' +
        'user will also delete this users application and data',
      )
    ) {
      const deleted = await deleteUser(userId, db)
      if (deleted) {
        setDeleting(false)
        setAlert({
          open: true,
          message: `Successfully deleted user`,
          type: 'success',
        })
        getUserList()
      }

      if (!deleted) {
        setDeleting(false)
        setAlert({
          open: true,
          message: `Error deleting user, please try again`,
          type: 'error',
        })
      }
    }
    setDeleting(false)
  }

  const getUserList = async () => {
    const userList = await listUsers(db)
    if (userList) {
      setUserList(userList)
      setUnfilteredUsers(userList)
    }
    setLoadingList(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  const handleSearch = (event) => {
    const value = event.target.value
    setSearch(value)
  }

  const filterList = (filter) => {
    let list = [...unfilteredUsers];
    if (filter === 'full-member') list = unfilteredUsers.filter((user) => user.role === 'member' && user.registeredId)
    else if (filter === 'in-training') list = unfilteredUsers.filter((user) => user.role === 'member' && !user.registeredId)

    setUserList(list)
  }

  const searchList = () => {
    const searchValue = search.toLowerCase()
    const list = unfilteredUsers.filter((user) => user.firstName.toLowerCase().includes(searchValue)
      || user.lastName.toLowerCase().includes(searchValue)
      || user.email.toLowerCase().includes(searchValue))
    setUserList(list)
  }

  const callback = () => {
    if (search) searchList()
    else getUserList()
    setShowAdditionalInfo(false)
  }

  if (currentUser && ['admin', 'super-admin'].includes(currentUser.role)) {
    return (
      <section>
        <Container fixed sx={{ marginTop: '50px', maxWidth: '100%' }}>
          <Snackbar
            autoHideDuration={6000}
            onClose={handleClose}
            open={alert.open}
          >
            <Alert
              onClose={handleClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Typography mb={6} variant="h4">
            Manage Users
          </Typography>
          <Link to="/admin/dashboard">{'< Back to Admin Dashboard'}</Link>

          <Dialog fullWidth maxWidth="sm" open={showAdditionalInfo} onClose={() => setShowAdditionalInfo(false)}>
            <DialogTitle>Additional Details</DialogTitle>
            <DialogContent>
              <AdditionalData user={selectedUser} callback={callback} />
            </DialogContent>
          </Dialog>

          <Container
            component="main"
            sx={{ marginTop: 5, maxWidth: '100%' }}
          >
            <Grid container sx={{ justifyContent: 'space-between', display: 'flex' }} spacing={2}>
              <Grid item>
                <Button variant='outlined' sx={{ mr: 1, mb: 1 }} onClick={() => filterList('full-member')} >Show Fully Registered Members</Button>
                <Button variant='outlined' sx={{ mr: 1, mb: 1 }} onClick={() => filterList('in-training')} >Show In Training Members</Button>
                <Button variant='outlined' sx={{ mb: 1 }} onClick={() => filterList('all')} >Show All</Button>
              </Grid>
              <Grid item>
                <OutlinedInput
                  fullWidth
                  size='small'
                  value={search}
                  id="search-field"
                  placeholder="Search"
                  name="search-field"
                  onChange={handleSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon onClick={searchList} />
                      {search !== '' && (
                        <CloseIcon
                          onClick={() => {
                            setUserList(unfilteredUsers)
                            setSearch('')
                          }}
                        />
                      )}
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Container>
          <Container
            component="main"
            sx={{ marginTop: 2, marginBottom: 5, maxWidth: '100%' }}
          >
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid
                checkboxSelection={false}
                loading={loadingList}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                rows={userList}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
              />
            </div>
          </Container>
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Alert severity="error">You are not authorized to view this page</Alert>
      </Container>
    </section>
  )
}

export default UserTable
