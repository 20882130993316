/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react'
import {
  Alert,
  Container,
  Typography,
  Snackbar,
  Grid,
  TextField,
  Autocomplete,
  Button,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Box,
  Tabs,
  Tab,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import DownloadIcon from '@mui/icons-material/Download'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {
  getStorage,
  ref,
  getDownloadURL,
  listAll,
  uploadBytesResumable,
} from 'firebase/storage'
import { useAuth } from '../contexts/AuthContext'
import { updateApplication } from '../data/application'
import { ApplicationContext } from '../contexts/application/ApplicationContext'
import { LoadingButton } from '@mui/lab'
import TrainingLog from '../components/TrainingLog'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const MembersArea = () => {
  const { currentUser, db } = useAuth()

  const { application, fetchApplication } = useContext(ApplicationContext)

  const [percent, setPercent] = useState(0)
  const [selectedResource, setSelectedResource] = useState('')
  const [selectedWorksheet, setSelectedWorksheet] = useState('')
  const [showTrainerDetailsModal, setShowTrainerDetailsModal] = useState(false)
  const [trainerDetails, setTrainerDetails] = useState(application)
  const [updating, setUpdating] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [resources, setResources] = useState([])
  const [worksheets, setWorksheets] = useState([])
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  const storage = getStorage()
  const worksheetsListRef = ref(storage, 'worksheets')
  const resourcesListRef = ref(storage, 'resources')

  useEffect(() => {
    if (currentUser.role === 'trainer' && currentUser.linkedAccount) fetchApplication(currentUser.linkedAccount, db)
    else fetchApplication(currentUser.uid, db)
    fetchFileList()
  }, [])

  useEffect(() => {
    setTrainerDetails({ ...application, ...trainerDetails })
  }, [application])

  const fetchFileList = () => {
    listAll(worksheetsListRef)
      .then((res) => {
        const mappedFiles = res.items.map((item) => ({
          label: item._location.path.replace('worksheets/', ''),
          value: item._location.path,
        }))
        setWorksheets(mappedFiles)
      })
      .catch((error) => {
        setAlert({
          open: true,
          message:
            'Error fetching worksheets list. Please refresh or try again later',
          type: 'error',
        })
      })

    listAll(resourcesListRef)
      .then((res) => {
        const mappedFiles = res.items.map((item) => ({
          label: item._location.path.replace('resources/', ''),
          value: item._location.path,
        }))
        setResources(mappedFiles)
      })
      .catch((error) => {
        setAlert({
          open: true,
          message:
            'Error fetching reports & studies list. Please refresh or try again later',
          type: 'error',
        })
      })
  }

  const downloadFile = (fileName) => {
    const storage = getStorage()
    const fileRef = ref(storage, `gs://pads-foundation.appspot.com/${fileName}`)

    getDownloadURL(fileRef)
      .then((url) => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = (event) => {
          const blob = xhr.response
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download = `${fileName}`
          a.dispatchEvent(new MouseEvent('click'))
        }
        xhr.open('GET', url)
        xhr.send()
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: 'Error downloading file. Please try again later',
          type: 'error',
        })
      })
  }

  const uploadToFirebase = (file, fileName, folder) => {
    const storageRef = ref(storage, `/${folder}/${fileName}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const currentPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setPercent(currentPercent)
      },
      (err) => console.error(err),
      () => {
        setAlert({
          open: true,
          message: 'File uploaded successfully',
          type: 'success',
        })
        fetchFileList()
      },
    )
  }

  const updateApplicationDetails = async () => {
    if (!trainerDetails.trainerName && !trainerDetails.trainerPhone) {
      setShowErrors(true)
    } else {
      setUpdating(true)

      const updateApp = await updateApplication(
        trainerDetails,
        currentUser.uid,
        db,
      )
      if (updateApp) {
        setUpdating(false)
        setAlert({
          open: true,
          message: 'Trainer details added successfully',
          type: 'success',
        })
        fetchApplication(currentUser.uid, db)
        closeModal()
      }

      if (!updateApp) {
        setUpdating(false)
        setAlert({
          open: true,
          message: `Error saving trainer details, please try again`,
          type: 'error',
        })
      }
    }
  }

  const handleTrainerDetailsChange = (event) => {
    const value = event.target.value
    const name = event.target.name
    setTrainerDetails({ ...application, ...trainerDetails, [name]: value })
  }

  const closeModal = () => {
    setShowTrainerDetailsModal(false)
    setShowErrors(false)
    setUpdating(false)
  }

  if (
    currentUser &&
    ['member', 'admin', 'super-admin', 'trainer'].includes(currentUser.role)
  ) {
    if (currentUser.role === 'trainer' && !currentUser.linkedAccount) {
      return (
        <section>
          <Container sx={{ marginTop: '50px', marginBottom: '367px' }} xs={12} >
            <Typography mb={6} variant="h4">
              Members Area
            </Typography>
            <Alert severity='warning'>You are not linked to any accounts. Please contact <a href="mailto:info@pads.foundation">info@pads.foundation</a> to link your trainer account with a member</Alert>
          </Container>
        </section>
      )
    }

    return (
      <section>
        <Container sx={{ marginTop: '50px' }} xs={12}>
          <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Typography mb={6} variant="h4">
            Members Area
          </Typography>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              sx={{ width: { xs: '350px', sm: '100%' } }}
              value={value}
              onChange={handleChange}
              aria-label="member options"
              variant="scrollable"
              allowScrollButtonsMobile
              scrollButtons="auto"
            >
              <Tab label="Training Guide" />
              <Tab label="Worksheets & Documents" />
              <Tab label="Training Log" />
              {currentUser.role !== 'trainer' && <Tab label="Trainer Details" />}
            </Tabs>
          </Box>
          <div
            role="tabpanel"
            hidden={value !== 0}
            id="training-guide"
            style={{ minHeight: '39vh' }}
            aria-labelledby="training-guide"
          >
            {value === 0 && (
              <Box sx={{ p: 3 }}>
                <Typography mb={3} variant="h5">
                  Training Guide
                </Typography>
                <Link to="/members/training-guide/introduction">
                  Introduction
                </Link>
                <br />
                <Link to="/members/training-guide/learning-theory">
                  Chapter 1: Canine Learning & Theory
                </Link>
                <br />
                <Link to="/members/training-guide/puppy-development">
                  Chapter 2: Puppy Development
                </Link>
                <br />
                <Link to="/members/training-guide/canine-care">
                  Chapter 3: Canine Care
                </Link>
                <br />
                <Link to="/members/training-guide/body-language">
                  Chapter 4: Communication & Body Language
                </Link>
                <br />
                <Link to="/members/training-guide/behaviour">
                  Chapter 5: Managing Canine Behaviour
                </Link>
                <br />
                <Link to="/members/training-guide/public-access">
                  Chapter 6: Public Access Guidelines
                </Link>
                <br />
                <Link to="/members/training-guide/uk-dog-law">
                  Chapter 7: UK Dog Law
                </Link>
              </Box>
            )}
          </div>

          <div
            role="tabpanel"
            hidden={value !== 1}
            id="worksheets"
            aria-labelledby="worksheets"
          >
            {value === 1 && (
              <Box sx={{ p: 3 }}>
                <Typography mb={3} variant="h5">
                  Worksheets & Supplementary files
                </Typography>
                <Grid item xs={12} sx={{ display: 'flex', mb: 5 }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-resources"
                    options={worksheets}
                    sx={{ width: 450 }}
                    onChange={(event, newInputValue) => {
                      setSelectedWorksheet(newInputValue.value)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Please select a file to download..."
                      />
                    )}
                  />
                  <IconButton
                    aria-label="delete"
                    sx={{ width: '55px' }}
                    onClick={() => downloadFile(selectedWorksheet)}
                  >
                    <DownloadIcon />
                  </IconButton>
                  {!!currentUser &&
                    ['admin', 'super-admin'].includes(currentUser.role) && (
                      <Button
                        sx={{ ml: 3 }}
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload file
                        <VisuallyHiddenInput
                          type="file"
                          onChange={(e) =>
                            uploadToFirebase(
                              e.target.files[0],
                              e.target.files[0].name,
                              'worksheets',
                            )
                          }
                        />
                      </Button>
                    )}
                </Grid>

                <Typography mb={3} mt={5} variant="h5">
                  Reports and Studies
                </Typography>
                <Grid item xs={12} sx={{ display: 'flex', mb: 5 }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-resources"
                    options={resources}
                    sx={{ width: 450 }}
                    onChange={(event, newInputValue) => {
                      setSelectedResource(newInputValue.value)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Please select a file to download..."
                      />
                    )}
                  />
                  <IconButton
                    aria-label="delete"
                    sx={{ width: '55px' }}
                    onClick={() => downloadFile(selectedResource)}
                  >
                    <DownloadIcon />
                  </IconButton>
                  {!!currentUser &&
                    ['admin', 'super-admin'].includes(currentUser.role) && (
                      <>
                        <Button
                          sx={{ ml: 3 }}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload file
                          <VisuallyHiddenInput
                            type="file"
                            onChange={(e) =>
                              uploadToFirebase(
                                e.target.files[0],
                                e.target.files[0].name,
                                'resources',
                              )
                            }
                          />
                        </Button>
                        {percent > 0 && percent < 100 && (
                          <LinearProgress
                            variant="determinate"
                            value={percent}
                            sx={{
                              width: '150px',
                            }}
                          />
                        )}
                      </>
                    )}
                </Grid>
              </Box>
            )}
          </div>
          <div
            role="tabpanel"
            hidden={value !== 2}
            id="training-log"
            style={{ minHeight: '39vh' }}
            aria-labelledby="training-log"
          >
            {value === 2 && (
              <Box sx={{ p: 3 }}>
                <Typography mb={3} variant="h5">
                  Training Log
                </Typography>
                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                  How to log training
                </Typography>

                <Typography sx={{ mb: 2 }}>
                  Active training is best kept to approximately 15 minutes in
                  duration. If you are with your trainer you can log the full
                  hour (as you are also learning during this time). However if
                  you take your dog for an hours walk it is likely you will only
                  be actively training for 15 minutes in total during that walk,
                  you would therefore log 15 of training.
                </Typography>
                <TrainingLog viewOnly={currentUser.role === 'trainer'} />
              </Box>
            )}
          </div>
          <div
            role="tabpanel"
            hidden={value !== 3}
            id="trainer-details"
            style={{ minHeight: '39vh' }}
            aria-labelledby="trainer-details"
          >
            {value === 3 && (
              <Box sx={{ p: 3 }}>
                <Typography variant="h5">Trainer Details</Typography>
                {!application?.trainerName && (
                  <Typography>No trainer details found</Typography>
                )}
                {!!application?.trainerName && (
                  <>
                    <Typography>{application.trainerName}</Typography>
                    <Typography>{application.trainerBusinessName}</Typography>
                    <Typography>{application.trainerPhone}</Typography>
                    <Typography>{application.trainerEmail}</Typography>
                    <Typography>{application.traineCity}</Typography>
                  </>
                )}
                <Button
                  onClick={() => setShowTrainerDetailsModal(true)}
                  sx={{ mb: 3, mt: 1 }}
                  component="label"
                  variant="contained"
                >
                  {application?.trainerName
                    ? 'Update Trainer Details'
                    : 'Add Trainer Details'}
                </Button>
                <Dialog
                  open={showTrainerDetailsModal}
                  onClose={closeModal}
                  maxWidth="xs"
                >
                  <DialogTitle>Trainer Details</DialogTitle>
                  <DialogContent>
                    <Typography>
                      Please add your trainer details below:
                    </Typography>
                    <TextField
                      id="trainerName"
                      fullWidth
                      label="Trainer Name"
                      name="trainerName"
                      onChange={handleTrainerDetailsChange}
                      defaultValue={application?.trainerName || ''}
                      sx={{ marginTop: 3 }}
                    />
                    {!!showErrors && (
                      <FormHelperText error>
                        Please enter your trainer name
                      </FormHelperText>
                    )}
                    <TextField
                      id="trainerBusinessName"
                      fullWidth
                      label="Trainer Website"
                      name="trainerBusinessName"
                      onChange={handleTrainerDetailsChange}
                      defaultValue={application?.trainerBusinessName || ''}
                      sx={{ marginTop: 3 }}
                    />
                    <TextField
                      id="trainerPhone"
                      fullWidth
                      label="Trainer Phone"
                      name="trainerPhone"
                      onChange={handleTrainerDetailsChange}
                      defaultValue={application?.trainerPhone || ''}
                      sx={{ marginTop: 3 }}
                    />
                    {!!showErrors && (
                      <FormHelperText error>
                        Please enter your trainer&apos;s phone number
                      </FormHelperText>
                    )}
                    <TextField
                      id="trainerEmail"
                      fullWidth
                      label="Trainer Email"
                      name="trainerEmail"
                      onChange={handleTrainerDetailsChange}
                      defaultValue={application?.trainerEmail || ''}
                      sx={{ marginTop: 3 }}
                    />
                    <TextField
                      id="trainerCity"
                      fullWidth
                      label="Trainer Location/City"
                      name="trainerCity"
                      onChange={handleTrainerDetailsChange}
                      defaultValue={application?.trainerCity || ''}
                      sx={{ marginTop: 3 }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={closeModal}
                      sx={{ mt: 1 }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      onClick={updateApplicationDetails}
                      sx={{ mt: 1 }}
                      loading={updating}
                      variant="contained"
                    >
                      Submit
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
              </Box>
            )}
          </div>
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Typography mb={6} variant="h4">
          Members Area
        </Typography>
        <Link to="/application">{'< Back to Application Home'}</Link>

        <Container
          component="main"
          maxWidth="md"
          sx={{ marginTop: 5, marginBottom: 5 }}
        >
          <Alert severity="error">This area is for members only</Alert>
        </Container>
      </Container>
    </section>
  )
}

export default MembersArea
