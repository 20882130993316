import React, { useState, useEffect, useContext, useMemo } from 'react'
import {
  Alert,
  Container,
} from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'

import { useAuth } from '../../contexts/AuthContext'
import { RenewalContext } from '../../contexts/renewal/RenewalContext'
import VetRenewal from '../../components/Applications/VetRenewal'

const ApplicationRenewal = () => {
  const { currentUser, db } = useAuth()
  const { renewals, fetchRenewals } = useContext(RenewalContext)
  const [renewalList, setRenewalList] = useState([])

  useEffect(() => {
    fetchRenewals(currentUser.uid, db)
  }, [])

  useEffect(() => {
    if (renewals) {
      setRenewalList(renewals)
    }
  }, [renewals])

  const methods = useForm({
    defaultValues: useMemo(() => {
      return renewalList.find(((app) => app.status === 'Awaiting submission') || {})
    }, [renewalList]),
  })

  if (currentUser && ['member', 'admin', 'super-admin'].includes(currentUser.role)) {
    return (
      <section>
        <Container fixed sx={{ marginTop: '50px' }}>
          <FormProvider {...methods}>
            <VetRenewal renewalList={renewalList || []} currentRenewal={renewalList.find(((app) => app.status === 'Awaiting submission'))} goBack={() => { }} />
          </FormProvider>
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Alert severity="error">You are not authorized to view this page</Alert>
      </Container>
    </section>
  )
}

export default ApplicationRenewal
