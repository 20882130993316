/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react'
import { Grid, MenuItem, Alert, Skeleton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../contexts/AuthContext'
import { listApplications } from '../../data/application'
import { linkTrainer } from '../../data/trainers'
import { Select } from '@mui/material'

const LinkTrainerDetails = ({ callback, trainerId }) => {
  const {
    handleSubmit,
  } = useForm()
  const { db } = useAuth()

  const [errorMessage, setErrorMessage] = useState('')
  const [applicationList, setApplicationList] = useState()
  const [loading, setLoading] = useState(false)
  const [loadingList, setLoadingList] = useState(true)

  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => {
    getApplicationList()
  }, [])

  const getApplicationList = async () => {
    const applicationList = await listApplications(db)
    if (applicationList) setApplicationList(applicationList)
    setLoadingList(false)
  }

  const onSubmit = async () => {
    setLoading(true)
    setErrorMessage('')

    if (selectedUser) {
      const name = `${selectedUser.firstName} ${selectedUser.lastName}`

      try {
        await linkTrainer(db, trainerId, selectedUser.id, name)

        if (callback) callback()
      } catch (error) {
        setLoading(false)
        const errorMessage = error.message
        setErrorMessage(errorMessage)
      }
    } else {
      setLoading(false)
    }
  }

  const handleChange = (event) => {
    const app = applicationList.find((app) => app.id === event.target.value)
    if (app) setSelectedUser(app)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!!loadingList && (
            <Skeleton height="40px" />
          )}
          {!loadingList && (
            <Select
              labelId="select-member"
              id="select-member"
              fullWidth
              sx={{ width: '100%' }}
              value={selectedUser ? selectedUser.id : '0'}
              onChange={(handleChange)}
            >
              <MenuItem value='0'>Please select a member</MenuItem>
              {applicationList?.map((app) => (
                <MenuItem key={app.id} value={app.id}>{app.firstName} {app.lastName}: {app.email}</MenuItem>
              ))}
            </Select>
          )}
        </Grid>
      </Grid>
      {!!errorMessage && (
        <Alert sx={{ marginTop: 2 }} severity="error">
          {errorMessage}
        </Alert>
      )}
      <Grid justifyContent="end" container>
        <LoadingButton
          loading={loading}
          type="button"
          onClick={() => callback()}
          variant="contained"
          color="info"
          sx={{ mt: 3, mb: 2, mr: 1 }}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Save
        </LoadingButton>
      </Grid>
    </form>
  )
}

export default LinkTrainerDetails
