import React, { useEffect, useContext } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { ApplicationContext } from '../../contexts/application/ApplicationContext'
import { Container, Alert, Typography, Grid } from '@mui/material'

const TrainerApplications = () => {
  const { currentUser, db, functions } = useAuth()

  const { application, fetchApplication } = useContext(ApplicationContext)

  useEffect(() => {
    if (currentUser.linkedAccount) {
      fetchApplication(currentUser.linkedAccount, db);
    }
  }, [currentUser.linkedAccount])

  if (currentUser.role !== 'trainer') {
    return (
      <section>
        <Container fixed sx={{ marginTop: '50px', marginBottom: '457px' }}>
          <Alert severity="error">You are not authorized to view this page</Alert>
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px', mb: 5, marginBottom: !currentUser.linkedAccount ? '367px' : 0 }}>
        <Typography mb={6} variant="h4">
          Linked Account
        </Typography>
        {!currentUser.linkedAccount && (
          <Alert severity='warning'>You are not linked to any accounts. Please contact <a href="mailto:info@pads.foundation">info@pads.foundation</a> to link your trainer account with a member</Alert>
        )}
        {!!application && !!currentUser.linkedAccount && (
          <Grid sx={{ mb: '351px' }}>
            <Typography variant="body1" mb={2}>
              <strong>Name:</strong> {application?.firstName}{' '}
              {application?.lastName}
            </Typography>
            <Typography variant="body1" mb={2}>
              <strong>Application Status:</strong> {application?.status}
            </Typography>
          </Grid>
        )}
      </Container>
    </section>
  )
}

export default TrainerApplications
