export const firestoreAutoId = () => {
    const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    let autoId = ''

    for (let i = 0; i < 20; i++) {
        autoId += CHARS.charAt(
            Math.floor(Math.random() * CHARS.length),
        )
    }
    return autoId
}

export const getAge = (from, todate) => {
    if (todate) todate = new Date(todate);
    else todate = new Date();

    const age = []
    const fromdate = new Date(from)
    const y = [todate.getFullYear(), fromdate.getFullYear()]
    let ydiff = y[0] - y[1]
    const m = [todate.getMonth(), fromdate.getMonth()]
    let mdiff = m[0] - m[1]
    const d = [todate.getDate(), fromdate.getDate()]
    let ddiff = d[0] - d[1];

    if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) --ydiff;
    if (mdiff < 0) mdiff += 12;
    if (ddiff < 0) {
        fromdate.setMonth(m[1] + 1, 0);
        ddiff = fromdate.getDate() - d[1] + d[0];
        --mdiff;
    }
    if (ydiff > 0) age.push(ydiff + " year" + (ydiff > 1 ? "s " : " "));
    if (mdiff > 0) age.push(mdiff + " month" + (mdiff > 1 ? "s" : ""));
    if (age.length > 1) age.splice(age.length - 1, 0, "and ");
    return age.join("");
}
