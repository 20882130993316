import React from 'react'
import { Grid, Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Support = () => {
  return (
    <section>
      <Container fixed sx={{ marginTop: '50px', mb: 17.8 }}>
        <Typography mb={6} variant="h4">
          Help & Support
        </Typography>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h5">
              How do I submit my In-Training Application?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography mb={3} variant="body1">
              Please download and fill out the forms from the dashboard. Once you have all the forms filled out, you&apos;re ready to start your application!
            </Typography>
            <Grid container>
              <Grid item xs={10} sx={{ border: '1px solid #CCC', mb: 2 }}>
                <img src="/assets/member-apply.png" width="100%" />
              </Grid>
            </Grid>
            <Typography mb={3} variant="body1">
              You will need to copy the details from your forms in to the application, remembering to upload a copy of your form for review (just a photograph is fine!)
            </Typography>
            <Typography mb={3} variant="body1">
              Once you have started filling out the application - you can view your progress and see what&apos;s left to complete
            </Typography>
            <Grid container>
              <Grid item xs={10} sx={{ border: '1px solid #CCC', mb: 2 }}>
                <img src="/assets/member-progress.png" width="100%" />
              </Grid>
            </Grid>
            <Typography mb={3} variant="body1">
              When you have filled everything out and uploaded your forms, you are ready to submit! A member of the team will then review your application and let you know via email if it was successful.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h5">
              What do I do once I have finished training?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ mb: 2 }}>
              Once you have finished the required training and completed the Public Access Test, you can then apply to become a fully registered PADs member.
            </Typography>
            <Typography>
              You should now be able to view the following information from your dashboard. If for some reason you don&apos;t see the screen below - please contact <a href="mailto:support@pads.foundation">support@pads.foundation</a>
            </Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={10} sx={{ border: '1px solid #CCC', mb: 2 }}>
                <img src="/assets/full-member.png" width="100%" />
              </Grid>
            </Grid>
            <Typography sx={{ mb: 2 }}>
              You will need to download the Annual Veterinary Form and fill this out with your registered Vet. Once you have these details, you can then click &apos;Manage Renewals&apos; and fill out the relevant data - remembeing to upload a copy of your form for review
            </Typography>
            <Typography sx={{ mb: 2 }}>
              When you have submitted the application - you can view the status from the &apos;Manage Renewals&apos; page. Here you can view any previous applications or renewals. A member of the team will review your application and let you know via email if it was successful.
            </Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={10} sx={{ border: '1px solid #CCC', mb: 2 }}>
                <img src="/assets/renewals.png" width="100%" />
              </Grid>
            </Grid>
            <Typography>
              Once your application has been approved, we will send out reminders approximately 2 months before the next renewal is due.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h5">
              How do I manage my renewal each year?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ mb: 2 }}>
              You should have received a reminder via email when your renewal is due.
            </Typography>
            <Typography>
              For each renewal, you will need to download a new copy of the Annual Veterinary Form and fill this out with your registered vet. Once you have these details you can fill out the renewal form online and upload a copy of the form via the &apos;Manage Renewals&apos; button on the dashboard
            </Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={10} sx={{ border: '1px solid #CCC', mb: 2 }}>
                <img src="/assets/renewal-form.png" width="100%" />
              </Grid>
            </Grid>
            <Typography sx={{ mb: 2 }}>
              When you have submitted the application - you can view the status by clicking the &apos;Manage Renewals&apos; button on the dashboard. Here you can view any previous applications or renewals at the top of the page. A member of the team will review your renewal and let you know via email if it was successful.
            </Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={10} sx={{ border: '1px solid #CCC', mb: 2 }}>
                <img src="/assets/renewals.png" width="100%" />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Typography variant="h5" sx={{ mt: 4 }}>
          Got any questions or need help?
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Please contact us at <a href="mailto:support@pads.foundation">support@pads.foundation</a>
        </Typography>
      </Container>
    </section >
  )
}

export default Support
