import React from "react"
import { Typography, Container, List, ListItem, Grid } from "@mui/material"

const TrainerTermsAndConditions = () => {
  return (
    <section>
      <Grid sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <img
          src="https://pads.foundation/wp-content/uploads/2020/08/cropped-padsLogo150.png"
          className="custom-logo"
          alt="Psychiatric Assistance Dogs Foundation"
          style={{ marginRight: '15px' }}
        />
      </Grid>
      <Container fixed sx={{ marginTop: '50px', marginBottom: '50px' }}>
        <Typography mb={6} variant="h4">
          PADs Foundation Trainer Standards and Code of Conduct
        </Typography>
        <Typography>
          As an Organisational Memter of the IAABC we follow their Standards and Code of Conduct –
          (please note wording has been adjusted to refer to PADs Trainers) for original document please
          see: https://iaabc.org/code-of-ethic
        </Typography>
        <Typography sx={{ mt: 3 }}>
          All Volunteer Trainers are required to follow the Joint Standards of Practice and Code of Ethics
          adopted by PADs Foundation and originally by the IAABC in 2018. Our standards are intended to
          build animal behaviour practice as a profession that holds a high degree of trust from the general
          public / PADs Memters. The IAABC educates its memters on how to follow these standards and
          holds them accountable through their ethics complaint process. All PADs Volunteer Trainers must
          read and agree to these requirements.
        </Typography>
        <Typography mt={6} variant="h4">
          Joint Standards of Practice and Professional Code of Ethics
        </Typography>
        <Typography mt={6} variant="h5">
          Standards of Practice
        </Typography>
        <Typography sx={{ mt: 3 }}>
          The Standards of Practice provide a framework of principles to convey a collective principle of
          professionalism, skills and values in positive reinforcement-based training. As PADs Trainers
          each individual trainer will undertake the following:
        </Typography>
        <List>
          <ListItem>
            To understand and promote Least Intrusive, Minimally Aversive (LIMA) training and
            behaviour work.
          </ListItem>
          <ListItem>
            To continue professional development by reading relevant material; attending
            conferences, workshops and seminars; and pursuing other educational opportunities.
          </ListItem>
          <ListItem>
            To review and understand source material and academic texts for information.
          </ListItem>
          <ListItem>
            To abstain from representing training and behavioural information as scientific, unless the
            information is derived from peer-reviewed and published research.
          </ListItem>
          <ListItem>
            To refrain from offering guarantees regarding the outcome of training and behaviour work.
          </ListItem>
          <ListItem>
            To always maintain professionalism through: Providing your services honestly
          </ListItem>
          <ListItem>
            Treating animals and clients respectfully
          </ListItem>
          <ListItem>
            Valuing and preserving the privacy of clients
          </ListItem>
          <ListItem>
            Maintaining professionalism with colleagues and other professionals.
          </ListItem>
        </List>
        <Typography mt={6} variant="h5">
          Professional Code of Ethics
        </Typography>
        <Typography sx={{ mt: 3 }}>
          The Professional Code of Ethics was created to set forth guidelines for Trainers. Any Trainer is
          subject to termination of role, and/or other disciplinary actions if they: (a) are convicted of a
          felony that involves violence against people or animals (b) engage in conduct which could lead
          to conviction of a felony, or a misdemeanour, related to their qualifications or functions; (c)
          engage in cruelty, abuse, or neglect of animals or humans, crimes against humanity or of
          violence against animals or humans; (d) fail to cooperate with the organisation at any point from
          the inception of an ethical complaint through the completion of all proceedings regarding that
          complaint. Any Trainer, who wishes to appeal the role termination may do so in accordance with
          the complaints process.
        </Typography>
        <Typography mt={6} variant="h5">
          Principle I: Responsibility to Clients
        </Typography>
        <List>
          <ListItem>
            1.1 Trainers/behaviour consultants provide professional assistance to persons without
            discrimination on the basis of race, age, ethnicity, socioeconomic status, disability, gender,
            health status, religion, political beliefs, national origin, or sexual orientation.
          </ListItem>
          <ListItem>
            1.2 Trainers/behaviour consultants are aware of, and comply with, applicable laws regarding the
            reporting of animal bites and suspected abuse or neglect.
          </ListItem>
          <ListItem>
            1.3 Trainers/behaviour consultants respect the right of clients to make decisions regarding their
            pet&apos;s management, training and care. Trainers/behaviour consultants are responsible for helping
            clients understand the potential consequences of those decisions.
          </ListItem>
          <ListItem>
            1.4 Trainers/behaviour consultants obtain informed consent from clients before videotaping,
            audio recording, or permitting third-party observation.
          </ListItem>
          <ListItem>
            1.5 Trainers/behaviour consultants obtain signed waivers, contracts, or agreements prior to the
            start of services. Please note that PADs Trainers will obtain a PADs specific signed waiver.
          </ListItem>
          <ListItem>
            1.6 Trainers/behaviour consultants ensure and oversee the safety of clients, animals, and the
            public in implementing training and behaviour programs.
          </ListItem>
          <ListItem>
            1.7 Trainers/behaviour consultants create for clients, students, and colleagues a learning
            environment safe from harassment, including sexual harassment, in all work settings including
            industry-related social events, training sessions, and conferences. *
          </ListItem>
          <ListItem>
            Valuing and preserving the privacy of clients
          </ListItem>
          <ListItem>
            Maintaining professionalism with colleagues and other professionals.
          </ListItem>
        </List>
        <Typography mt={6} variant="h5">
          Principle II: Confidentiality
        </Typography>
        <List>
          <ListItem>
            2.1 Trainers/behaviour consultants do not share confidential information that could reasonably
            lead to the identification of a client, or prospective client, or other person with whom they have
            a confidential relationship, unless they have obtained the prior written consent of the client, or
            other person with whom they have a confidential relationship.
          </ListItem>
          <ListItem>
            2.2 Trainers/behaviour consultants keep accurate and complete records of all clients, their
            animals, services provided, and the conclusion of the services provided.
          </ListItem>
          <ListItem>
            2.3 Trainers/behaviour consultants provide referring veterinarians with professional feedback on
            services provided and training or behaviour plans to improve continuity of care and ensure the
            collaborative relationship between health and behaviour professionals.
          </ListItem>
        </List>
        <Typography mt={6} variant="h5">
          Principle III: Professional Competence and Integrity
        </Typography>
        <List>
          <ListItem>
            3.1 Trainers/behaviour consultants work to minimise the use of aversive stimuli and maximise
            the effective use of positive reinforcement to modify animal behaviour through a Least Intrusive,
            Minimally Aversive (LIMA) based approach.
          </ListItem>
          <ListItem>
            3.2 Trainers/behaviour consultants maintain competence in training and behaviour through
            continuing education.
          </ListItem>
          <ListItem>
            3.3 Trainers/behaviour consultants maintain adequate knowledge of, and adhere to, applicable
            laws, ethics, and professional standards.
          </ListItem>
          <ListItem>
            3.4 Trainers/behaviour consultants provide truthful advertising and representation concerning
            their qualifications, certifications, experience, performance and pricing of services
          </ListItem>
          <ListItem>
            3.5 Trainers/behaviour consultants refrain from providing guarantees regarding the specific
            outcome of training and behaviour plans.
          </ListItem>
          <ListItem>
            3.6 Trainers/behaviour consultants provide full disclosure of potential conflicts of interest to
            clients and other professionals.
          </ListItem>
          <ListItem>
            3.7 Trainers/behaviour consultants work within their professional education and individual
            expertise.
          </ListItem>
          <ListItem>
            3.8 Trainers/behaviour consultants seek help and education when confronted with complex or
            difficult cases, and refrain from taking cases beyond their professional experience.
          </ListItem>
          <ListItem>
            3.9 Trainers/behaviour consultants do not advise on problems outside the recognised
            professional education and certifications, and do not provide advice or recommendations in
            areas of veterinary medicine or family counselling unless licensed and qualified to do so.
          </ListItem>
          <ListItem>
            3.10 Trainers/behaviour consultants do not permit employees, subcontractors or supervisees to
            perform or to hold themselves out as competent to perform professional services beyond their training, level of experience, and competence based on certification and education.
          </ListItem>
          <ListItem>
            3.11 Trainers/behaviour consultants exercise care when stating their professional
            recommendations and opinions through public statements.
          </ListItem>
        </List>
        <Typography mt={6} variant="h5">
          Principle III: Professional Competence and Integrity
        </Typography>
        <List>
          <ListItem>
            4.1 Trainers/behaviour consultants are respectful of colleagues and other professionals and do
            not condemn the character of their professional acts, nor engage in public commentary,
            including commentary in public presentations, written media or on websites, internet discussion
            lists or social media, that is disrespectful, derisive or inflammatory. This includes cyberbullying,
            that is, the use of electronic media for deliberate, repeated and hostile behaviour against
            colleagues.
          </ListItem>
          <ListItem>
            4.2 Professional trainers/behaviour consultants maintain adequate professional liability insurance
            coverage.
          </ListItem>
        </List>
        <Typography mt={6} variant="h5">
          Principle V: Financial Arrangements & Truthful Representation of Services
        </Typography>
        <List>
          <ListItem>
            5.1 Prior to entering into the professional relationship, Trainers/behaviour consultants clearly
            disclose and explain to clients all financial arrangements and fees related to professional
            services.
          </ListItem>
          <ListItem>
            5.2 Trainers/behaviour consultants represent facts truthfully to clients, third party payers and
            students regarding services rendered.
          </ListItem>
        </List>
        <Typography mt={6} variant="h5">
          Principle VI: Advertising
        </Typography>
        <List>
          <ListItem>
            6.1 Trainers/behaviour consultants accurately represent their competencies, education, training,
            and experience relevant to their practice of training and behaviour.
          </ListItem>
          <ListItem>
            6.2 Trainers/behaviour consultants do not use titles that could mislead the public concerning the
            identity, responsibility, source, and status of those practicing under that name.
          </ListItem>
          <ListItem>
            6.3 Trainers/behaviour consultants correct, wherever possible, false, misleading, or inaccurate
            information and representations made by others concerning the consultant&apos;s qualifications,
            services, or products.
          </ListItem>
          <ListItem>
            6.4 Trainers/behaviour consultants do not represent themselves as providing specialised
            services unless they have the appropriate education, training, or experience.
          </ListItem>
          <ListItem>
            6.5 Trainers/behaviour consultants refrain from making misrepresentations regarding marketing
            and logos for which the practitioner is no longer eligible, and remove logos and claims of
            certification when no longer maintained by the practitioner.
          </ListItem>
          <ListItem>
            6.6 Trainers/behaviour consultants agree to use the most current logos, and follow
            recommended usage of said marketing materials.
          </ListItem>
          <ListItem>
            6.7 Trainers / behaviour consultants shall not commit business fraud, plagiarism or copyright
            infringement, misuse or misappropriation of logos, trademarks, theft of intellectual property,
            slander or libel.
          </ListItem>
        </List>
        <Typography sx={{ mt: 2 }}>* This is an IAABC memter guideline requirement and not part of the Joint Standards.</Typography>
      </Container>
    </section>
  )
}

export default TrainerTermsAndConditions
