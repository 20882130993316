/* eslint-disable max-len */
import React from 'react'
import { PropTypes } from 'prop-types'
import { BrowserRouter as Router, Navigate } from 'react-router-dom'
import { Routes, Route, Outlet } from 'react-router-dom'

import Signup from './pages/Signup'
import Application from './pages/Application'
import AdminDashboard from './pages/AdminDashboard'
import Login from './pages/Login'
import TrainerSignup from './pages/TrainerSignup'
import { useAuth } from './contexts/AuthContext'
import Footer from './components/Footer'
import Header from './components/Header'
import UserTable from './components/Users/UserTable'
import ApplicationTable from './components/Applications/ApplicationTable'
import CreateApplication from './components/Applications/CreateApplication'
import ViewApplication from './components/Applications/ViewApplication'
import ViewTrainingLog from './components/TrainingLog/ViewTrainingLog'
import ReadOnlyApplication from './components/Applications/ReadOnlyApplication'
import PuppyDevelopment from './components/members/TrainingGuide/PuppyDevelopment'
import DogLaw from './components/members/TrainingGuide/DogLaw'
import CanineCare from './components/members/TrainingGuide/CanineCare'
import Behaviour from './components/members/TrainingGuide/Behaviour'
import BodyLanguage from './components/members/TrainingGuide/BodyLanguage'
import PublicAccess from './components/members/TrainingGuide/PublicAccess'
import LearningTheory from './components/members/TrainingGuide/LearningTheory'
import Introduction from './components/members/TrainingGuide/Introduction'
import MembersArea from './pages/Members'
import VerifyEmail from './pages/VerifyEmail'
import ForgotPassword from './pages/ForgotPassword'
import AutoLogout from './components/AutoLogout'
import Landing from './components/Quiz/LandingPage'
import TermsAndConditions from './pages/TermsAndConditions'
import Support from './pages/Support'
import QuizPage from './pages/Quiz'
import { module1, module2, module3 } from './components/Quiz/quizQuestions'
import ApplicationRenewalTable from './components/Renewals/ApplicationRenewalTable'
import ApplicationRenewal from './components/Renewals/ApplicationRenewal'
import ViewRenewalsList from './components/Renewals/ViewRenewalsList'
import ViewRenewal from './components/Renewals/ViewRenewal'
import ManageForms from './components/ManageForms'
import TrainerApplications from './components/Trainer/TrainerApplications'
import TrainerTable from './components/Trainer/TrainerTable'
import TrainerTermsAndConditions from './pages/TrainerTermsAndConditions'

const PrivateRoute = ({ user }) => {
  if (user && !user.emailVerified) return <Navigate to="/verify-email" />
  return user && user.emailVerified ? <Outlet /> : <Navigate to="/login" />
}

PrivateRoute.propTypes = {
  user: PropTypes.object,
}

const App = () => {
  const { currentUser } = useAuth()

  const showHeader =
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/signup' &&
    window.location.pathname !== '/trainer-signup'

  return (
    <Router>
      {!!showHeader && !!currentUser && !!currentUser.emailVerified && (
        <Header />
      )}
      <AutoLogout>
        <Routes>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route exact path="/" element={<Application />} />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route exact path="/application" element={<Application />} />
          </Route>

          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/application/create"
              element={<CreateApplication />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/support"
              element={<Support />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route path="/members" element={<MembersArea />} />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              path="/members/training-guide/introduction"
              element={<Introduction />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              path="/members/training-guide/learning-theory"
              element={<LearningTheory />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              path="/members/training-guide/puppy-development"
              element={<PuppyDevelopment />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              path="/members/training-guide/canine-care"
              element={<CanineCare />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              path="/members/training-guide/body-language"
              element={<BodyLanguage />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              path="/members/training-guide/behaviour"
              element={<Behaviour />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              path="/members/training-guide/public-access"
              element={<PublicAccess />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              path="/members/training-guide/uk-dog-law"
              element={<DogLaw />}
            />
          </Route>

          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route path="/application/renewals/:id" element={<ViewRenewalsList />} />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route path="/application/renewal/:id" element={<ViewRenewal />} />
          </Route>

          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route path="/application/view/:id" element={<ViewApplication />} />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route path="/application/log/:id" element={<ViewTrainingLog />} />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              path="/application/read-only"
              element={<ReadOnlyApplication />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/application/renewals"
              element={<ApplicationRenewal />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/terms-full-application"
              element={<TermsAndConditions />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route exact path="/admin/manage-users" element={<UserTable />} />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route exact path="/admin/member-quiz" element={<Landing />} />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/admin/member-quiz/module1"
              element={<QuizPage module={module1} />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/admin/member-quiz/module2"
              element={<QuizPage module={module2} />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/admin/member-quiz/module3"
              element={<QuizPage module={module3} />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/admin/manage-applications"
              element={<ApplicationTable />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/admin/manage-forms"
              element={<ManageForms />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/admin/manage-renewals"
              element={<ApplicationRenewalTable />}
            />
          </Route>
          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/admin/manage-trainers"
              element={<TrainerTable />}
            />
          </Route>

          <Route exact element={<PrivateRoute user={currentUser} />}>
            <Route
              exact
              path="/trainer/applications"
              element={<TrainerApplications />}
            />
          </Route>
        </Routes>
      </AutoLogout>
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/trainer-signup" element={<TrainerSignup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/verify-email" element={<VerifyEmail />} />
        <Route exact path="/trainer-terms-and-conditions" element={<TrainerTermsAndConditions />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
