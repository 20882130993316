/* eslint-disable max-len */
const personalDetailsRequired = [
  { field: 'firstName', label: 'First Name' },
  { field: 'lastName', label: 'Last Name' },
  { field: 'email', label: 'Email' },
  { field: 'phone', label: 'Phone' },
  { field: 'address', label: 'Address' },
  { field: 'city', label: 'City' },
  { field: 'county', label: 'County' },
  { field: 'postcode', label: 'Post code' },
  { field: 'citizen', label: 'Are you a UK Citizen' },
  { field: 'nino', label: 'National Insurance Number' },
  { field: 'passportPhoto', label: 'Passport Photo' },
  { field: 'proofOfAddress', label: 'Proof of Address' },
  { field: 'identityDoc', label: 'Proof of Identity' },
]

const medicalRequired = [
  { field: 'medicalForm', label: 'Medical Form' },
  {
    field: 'concerns',
    label:
      'Do you have any concerns with regard to you patients ability to care for a dog',
  },
  {
    field: 'groom',
    label: 'Is your patient able to groom themselves regularly',
  },
  {
    field: 'forget',
    label: 'Does your patient forget to take medication',
  },
  {
    field: 'public',
    label: 'Does your patient become confused or `lost` in public',
  },
  {
    field: 'mood',
    label: 'Does your patient experience severe changes in mood',
  },
  {
    field: 'violent',
    label: 'Has your patient ever been violent towards animals or people',
  },
  { field: 'suicide', label: 'Has your patient attempted suicide' },
  {
    field: 'treatmentPlan',
    label: 'Please describe your patients current treatment plan',
  },
  {
    field: 'dogHelp',
    label: 'Do you believe that an assistance dog would assist your patient',
  },
  { field: 'dogSize', label: 'Does your patient suffer from Dissociations' },
  {
    field: 'longDiagnosis',
    label:
      'Has your patient had their psychiatric diagnosis for more than 12 months?',
  },
  {
    field: 'overEighteen',
    label: 'Is your patient over 18 years old?',
  },
  { field: 'medicalPracticeName', label: 'Practice/Healthcare Center Name' },
  { field: 'doctorName', label: 'Doctor or Psychiatrist Name' },
  { field: 'medicalPracticeAddress', label: 'Address' },
  { field: 'medicalPracticeCity', label: 'City' },
  { field: 'medicalPracticeCounty', label: 'County' },
  { field: 'medicalPracticePostcode', label: 'Post code' },
  { field: 'medicalPracticeEmail', label: 'Email' },
  { field: 'medicalPracticePhone', label: 'Phone' },
]

const dogDetailsRequired = [
  { field: 'dogPhoto', label: 'Photo of your dog' },
  { field: 'dogName', label: 'Name' },
  { field: 'dogAge', label: 'Age' },
  { field: 'dogBreed', label: 'Breed' },
  { field: 'dogWeight', label: 'Weight' },
  { field: 'microchipNumber', label: 'Microchip Number' },
  { field: 'insuranceCompany', label: 'Insurance Company' },
  { field: 'insurancePolicyNumber', label: 'Insurance Policy Number' },
  { field: 'insuranceStartDate', label: 'Insurance Start Date' },
]

const veterinaryInfoRequired = [
  { field: 'vetForm', label: 'Veterinary Form' },
  { field: 'vetName', label: 'Vet Name' },
  { field: 'practiceName', label: 'Practice Name' },
  { field: 'practiceAddress', label: 'Address' },
  { field: 'practiceCity', label: 'City' },
  { field: 'practiceCounty', label: 'County' },
  { field: 'practicePostcode', label: 'Post code' },
  { field: 'practiceEmail', label: 'Email' },
  { field: 'practicePhone', label: 'Phone' },
  { field: 'vaccinationDate', label: 'Vaccination Date' },
  { field: 'lastHealthCheckDate', label: 'Last Health Check Date' },
  { field: 'bodyScore', label: 'Body Score' },
  { field: 'neutered', label: 'Is the dog neutered?' },
  { field: 'pregnant', label: 'Is the dog pregnant?' },
  { field: 'boas', label: 'Is the dog brachycephalic?' },
  { field: 'toyBreed', label: 'Is the dog a toy breed?' },
  { field: 'bannedBreed', label: 'Is the dog a banned breed?' },
  {
    field: 'phenotypical',
    label: 'Does this breed display extreme phenotypical conformation?',
  },
  {
    field: 'illness',
    label: 'Is the dog free from signs of genetic or physical illness?',
  },
]

const veterinaryRenewalRequired = [
  { field: 'vetForm', label: 'Annual Veterinary Form' },
  { field: 'handlerName', label: 'Handler First Name' },
  { field: 'handlerLastName', label: 'Handler Last Name' },
  { field: 'dogName', label: 'Dog Name' },
  { field: 'dogBreed', label: 'Dog Breed' },
  { field: 'dogDob', label: 'Dog Date of Birth' },
  { field: 'sex', label: 'Sex' },
  { field: 'vetName', label: 'Vet Name' },
  { field: 'practiceName', label: 'Practice Name' },
  { field: 'practiceAddress', label: 'Address' },
  { field: 'practiceCity', label: 'City' },
  { field: 'practiceCounty', label: 'County' },
  { field: 'practicePostcode', label: 'Post code' },
  { field: 'practiceEmail', label: 'Email' },
  { field: 'microchipNumber', label: 'Microchip No.' },
  { field: 'dogWeight', label: 'Dog Weight' },
  { field: 'vaccinationDate', label: 'Vaccination Date' },
  { field: 'lastHealthCheckDate', label: 'Last Health Check Date' },
  { field: 'bodyScore', label: 'Body Score' },
  { field: 'neutered', label: 'Is the dog neutered?' },
  { field: 'boas', label: 'Is the dog brachycephalic?' },
  { field: 'senility', label: 'Checking for senility' },
  { field: 'sight', label: 'Eye and sight check' },
  { field: 'hearing', label: 'Ear and hearing check' },
  { field: 'joints', label: 'Joint and mobility check' },
  { field: 'dental', label: 'Dental/oral check' },
  { field: 'pain', label: 'Pain check' },
  { field: 'behaviour', label: 'Behaviour check during assessment' },
  { field: 'skin', label: 'Skin check' },
  { field: 'heart', label: 'Heart check' },
  { field: 'terms', label: 'Terms and Conditions' },
]

const sponsorRequired = [
  { field: 'sponsorFirstName', label: 'First Name' },
  { field: 'sponsorLastName', label: 'Last Name' },
  { field: 'sponsorEmail', label: 'Email' },
  { field: 'sponsorPhone', label: 'Phone' },
  { field: 'sponsorAddress', label: 'Address' },
  { field: 'sponsorCity', label: 'City' },
  { field: 'sponsorCounty', label: 'County' },
  { field: 'sponsorPostcode', label: 'Post code' },
]

export {
  personalDetailsRequired,
  dogDetailsRequired,
  veterinaryInfoRequired,
  sponsorRequired,
  medicalRequired,
  veterinaryRenewalRequired,
}
