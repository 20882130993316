import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
  orderBy,
} from 'firebase/firestore'
import dayjs from 'dayjs'

import { getUser, updateUser } from './user'

const min = 100000000000
const max = 999999999999

const createRenewal = async (data, uid, db, userId) => {
  try {
    // check if they have an in-progress renewal
    const q = query(collection(db, 'renewals'), where('userId', '==', userId))
    const querySnap = await getDocs(q)

    const inProgressApplications = querySnap.docs.filter((doc) => doc.data().status == 'Awaiting submission' || doc.data().status == 'Awaiting approval')

    if (inProgressApplications.length > 0) {
      return 'app-in-progress'
    }

    await setDoc(doc(db, 'renewals', uid), {
      ...data,
      createdOn: new Date(),
      updatedOn: new Date(),
    })

    return data
  } catch (error) {
    console.log(error)
    return false
  }
}

const updateRenewal = async (data, appId, userId, db) => {
  try {
    if (userId) {
      const userData = await getUser(userId, db)
      if (!userData.registeredId || userData.registeredId === '') {
        // generate registration ID number
        const id = Math.floor(Math.random() * (max - min + 1)) + min;
        const initials = `${Array.from(userData.firstName)[0]}${Array.from(userData.lastName)[0]}`
        const formattedId = `${id}${initials}`

        await setDoc(doc(db, 'users', userId), {
          registeredId: formattedId,
          updatedOn: new Date(),
          registeredOn: new Date(),
        }, { merge: true })
      }
    }

    await setDoc(doc(db, 'renewals', appId), {
      ...data,
      updatedOn: new Date(),
    }, { merge: true })

    return data
  } catch (error) {
    console.log(error)
    return false
  }
}

const deleteRenewal = async (data, uid, db) => {
  try {
    await deleteDoc(doc(db, 'renewals', uid))
    await updateUser({ hasApplication: true }, uid, db)

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

const listRenewals = async (db, userId) => {
  try {
    const q = query(collection(db, 'renewals'), where('userId', '==', userId), orderBy('updatedOn', 'desc'))
    const querySnap = await getDocs(q)

    const applications = querySnap.docs.map((doc) => {
      let updated = ''
      if (doc.data().updatedOn?.seconds) {
        updated = dayjs(doc.data().updatedOn.seconds * 1000).format(
          'DD MMM YYYY',
        )
      }
      let created = ''
      if (doc.data().createdOn?.seconds) {
        created = dayjs(doc.data().createdOn.seconds * 1000).format(
          'DD MMM YYYY',
        )
      }

      return {
        ...doc.data(),
        createdOn: created,
        updatedOn: updated,
        id: doc.id,
      }
    })

    return applications
  } catch (error) {
    console.error(error)
    return []
  }
}

const getCurrentRenewal = async (db, userId) => {
  try {
    const q = query(collection(db, 'renewals'), where('userId', '==', userId))
    const querySnap = await getDocs(q)

    const completedApplications = querySnap.docs.filter((doc) => doc.data().renewalDueDate)
    const inProgressApplications = querySnap.docs.filter((doc) => doc.data().status == 'Awaiting submission' || doc.data().status == 'Awaiting approval')

    if (inProgressApplications.length > 0) return inProgressApplications[0].data()

    if (completedApplications.length > 0) {
      const applicationSorted = completedApplications.sort((a, b) => a.renewalDueDate.seconds > b.renewalDueDate.seconds)
      const latestApplication = applicationSorted.pop()
      return latestApplication.data()
    }

    return null
  } catch (error) {
    console.error(error)
    return []
  }
}

const getRenewalById = async (db, uid) => {
  try {
    const docRef = doc(db, 'renewals', uid)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = docSnap.data()
      if (docSnap.data().renewalDueDate) {
        data.renewalDueDate = dayjs(docSnap.data().renewalDueDate.seconds * 1000).format(
          'DD/MM/YYYY',
        )
      }

      return data
    } else {
      console.log('no renewal found')
    }

    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

export {
  createRenewal,
  deleteRenewal,
  listRenewals,
  updateRenewal,
  getCurrentRenewal,
  getRenewalById,
}
