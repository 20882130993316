import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Container, Snackbar, Typography } from '@mui/material'

import { useAuth } from '../../contexts/AuthContext'
import TrainingLog from '.'

const ViewTrainingLog = () => {
  const { currentUser } = useAuth()
  const { id } = useParams()

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setAlert(false)
  }

  if (currentUser && ['admin', 'super-admin'].includes(currentUser.role)) {
    return (
      <section>
        <Container fixed sx={{ marginTop: '50px', mb: 3 }}>
          <Typography mb={6} variant="h4">
            Training Log
          </Typography>
          <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <TrainingLog selectedUser={id} viewOnly />
        </Container>
      </section>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Alert severity="error">You are not authorized to view this page</Alert>
      </Container>
    </section>
  )
}

export default ViewTrainingLog
