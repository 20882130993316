/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react'
import { Grid, Alert, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../contexts/AuthContext'
import { updateUser } from '../../data/user'

const AdditionalData = ({ callback, user }) => {
  const {
    handleSubmit,
  } = useForm()
  const { db } = useAuth()

  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [userDetails, setUserDetails] = useState(user)

  useEffect(() => {
    setUserDetails(user)
  }, [])

  const onSubmit = async () => {
    setLoading(true)
    setErrorMessage('')

    try {
      await updateUser(userDetails, userDetails.id, db)

      if (callback) callback()
    } catch (error) {
      setLoading(false)
      const errorMessage = error.message
      setErrorMessage(errorMessage)
    }
  }

  const handleChange = (event) => {
    const value = event.target.value
    const name = event.target.name

    setUserDetails({ ...userDetails, [name]: value })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6' sx={{ mb: 1 }}>
            Emergency Contact Details
          </Typography>
          <TextField
            name="emergencyContactName"
            fullWidth
            value={userDetails.emergencyContactName}
            id="emergencyContactName"
            label="Full Name"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={userDetails.emergencyContactEmail}
            id="emergencyContactEmail"
            label="Email"
            name="emergencyContactEmail"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' sx={{ mt: 3, mb: 1 }}>
            Emergency Information
          </Typography>
          <TextField
            rows={5}
            fullWidth
            value={userDetails.emergencyInfo1}
            id="emergencyInfo1"
            placeholder="Please add any additional information"
            name="emergencyInfo1"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            rows={5}
            fullWidth
            value={userDetails.emergencyInfo2}
            id="emergencyInfo2"
            placeholder="Please add any additional information"
            name="emergencyInfo2"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      {!!errorMessage && (
        <Alert sx={{ marginTop: 2 }} severity="error">
          {errorMessage}
        </Alert>
      )}
      <Grid justifyContent="end" container>
        <LoadingButton
          loading={loading}
          type="button"
          onClick={() => callback()}
          variant="contained"
          color="info"
          sx={{ mt: 3, mb: 2, mr: 1 }}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Save
        </LoadingButton>
      </Grid>
    </form>
  )
}

export default AdditionalData
