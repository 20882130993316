/* eslint-disable react/iframe-missing-sandbox */
/* eslint-disable max-len */
import React, { useEffect, useContext, useState } from 'react'
import {
  Alert,
  Container,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { pdfjs } from 'react-pdf'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { listRenewals } from '../../data/renewals'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

const ViewRenewalsList = () => {
  const { currentUser, db, functions } = useAuth()
  const navigate = useNavigate()

  const [renewals, setRenewals] = useState([])
  const [loading, setLoading] = useState(false)
  const [fileType, setFileType] = useState('')
  const [downloadUrl, setDownloadUrl] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'success',
  })

  const { id } = useParams()

  useEffect(() => {
    fetchRenewals()
  }, [])

  const fetchRenewals = async () => {
    const renewals = await listRenewals(db, id)
    setRenewals(renewals)
  }

  if (currentUser && ['admin', 'super-admin'].includes(currentUser.role)) {
    return (
      <Container fixed sx={{ marginTop: '50px' }}>
        <Typography mb={6} variant="h4">
          View Renewals
        </Typography>
        <Link to="/admin/manage-renewals">
          {'< Back to Manage Full Applications'}
        </Link>

        <Container
          component="main"
          maxWidth="md"
          sx={{ marginTop: 5, marginBottom: 36 }}
        >
          {renewals?.length > 0 && (
            <Grid mb={3}>
              <List component="nav">
                {renewals.map((app) => (
                  <ListItemButton key={app.id} onClick={() => navigate(`/application/renewal/${app.id}`)}>
                    <ListItemIcon sx={{ minWidth: '30px' }}>
                      <CircleIcon sx={{ width: '10px' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={<span><strong>{app.createdOn || app.updatedOn}</strong> - {app.status}</span>}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Grid>
          )}
        </Container>
      </Container>
    )
  }

  return (
    <section>
      <Container fixed sx={{ marginTop: '50px' }}>
        <Alert severity="error">You are not authorized to view this page</Alert>
      </Container>
    </section>
  )
}

export default ViewRenewalsList
