import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore'

const listTrainers = async (db) => {
  try {
    const q = query(collection(db, 'users'), where('role', '==', 'trainer'))
    const querySnap = await getDocs(q)

    const trainers = querySnap.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      }
    })

    return trainers
  } catch (error) {
    console.error(error)
    return []
  }
}

const linkTrainer = async (db, trainerId, userId, userName) => {
  try {
    await setDoc(doc(db, 'users', trainerId), {
      linkedAccount: userId,
      linkedAccountName: userName,
      updatedOn: new Date(),
    }, { merge: true })

    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

const unlinkTrainer = async (db, trainerId) => {
  try {
    await setDoc(doc(db, 'users', trainerId), {
      linkedAccount: '',
      linkedAccountName: '',
      updatedOn: new Date(),
    }, { merge: true })

    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export {
  listTrainers,
  linkTrainer,
  unlinkTrainer,
}
